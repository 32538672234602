<template>
    <div class="helpcenter">
        <div class="banner"> 
            <div class="banner-container">
                <div>
                    <h2>联系我们</h2>
                    <h4></h4>
                    <p>我们的商务团队衷心为您服务。</p>
                </div>
                <img src="../assets/img/lxr.png" alt="#">
            </div>
        </div>
        <div class="content">
            <div class="content-container">
                <div>
                    <h2>媒体/渠道合作</h2>
                    <!-- <p>用户注册：http://ssp.youxiaoad.com/，点击”注册“，填写资料，完成注册。</p> -->
                    <p>供应商资料审核：联系专属商务，完成供应商资料审核。</p>
                    <ul>
                        <li>
                            <div>
                                <span>周先生</span>
                            </div>
                            <div>
                                <span>微信：</span> 
                                <img src="../assets/img/ewm.png" alt="">
                            </div>
                            <div>
                                <span>手机：</span>
                                <span>18611158725</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <i></i>
                <h4></h4>
                <b></b>
                <div>
                    <h2>定向服务</h2>
                    <!-- <p>用户注册：http://ssp.youxiaoad.com/，点击”注册“，填写资料，完成注册。</p> -->
                    <p>供应商资料审核：联系专属商务，完成供应商资料审核。</p>
                    <ul>
                        <li>
                            <div>
                                <span>周先生</span>
                            </div>
                            <div>
                                <span>微信：</span> 
                                <img src="../assets/img/ewm.png" alt="">
                            </div>
                            <div>
                                <span>手机：</span>
                                <span>18611158725</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
       
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .helpcenter{
        .banner{
            background:url('../assets/img/type-banner-bg.png');
            background-size: contain;
            .banner-container{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                height: 430px;
                overflow: hidden;
                img:nth-of-type(1){
                    position: absolute;
                    top: 0px;
                    right: 70px;
                    width: 485px;
                }
                >div{
                    h2{
                        font-size: 40px;
                        font-weight: 600;
                    }
                    h4{
                        background: @themeColor;
                        width: 32px;
                        height: 4px;
                        margin: 12px 0 24px;
                    }
                    p{
                        font-size: 18px;
                        width: 530px;
                    }
                }
            }
        }
        .content{
            height: 720px;
            background: #fefeff;
            .content-container{
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                position: relative;
                h4{
                    position: absolute;
                    top:347px;
                    left: 25px;
                    transform: rotateZ(-48deg);
                    width: 880px;
                    height: 1px;
                    background: #ececee;
                }
                i{
                    position: absolute;
                    top:188px;
                    left: 578px;
                    transform: rotateZ(-48deg);
                    width: 68px;
                    height: 16px;
                    border-radius:0 0 4px 4px;
                    background: @themeColor; 
                }
                b{
                    position: absolute;
                    top:605px;
                    left: 200px;
                    transform: rotateZ(-48deg);
                    width: 20px;
                    height: 20px;
                    border-radius:4px 4px 0 0;
                    background: @themeColor; 
                }
                >div{
                    width: 800px;
                    height: 400px;
                    padding: 50px;
                    h2{
                        font-size: 26px;
                        font-weight: 600;
                        margin-bottom: 22px;
                    }
                    >p{
                        margin: 6px 0;
                    }
                    >ul{
                        width: 430px;
                        height: 200px;
                        li{
                          >div:nth-child(1){
                              font-size: 22px;
                              margin-top: 50px;
                          }
                          >div{
                              margin-top: 30px;
                              padding-left: 36px;
                              img{
                                  width: 150px;
                              }
                          }
                        }
                    }
                    &:last-child{
                        position: absolute;
                        padding-left: 180px;
                        right: 0px;
                        bottom: 58px;
                    }
                }
            }
        }
    }
   
</style>